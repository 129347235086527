<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row v-if="!loader">
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Division" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="division"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('warehouse_config.division') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="opening_balance.division_id"
                            :options="divisionList"
                            id="division_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :disabled="isWareHouseUser"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Region" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="region_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('warehouse_config.region') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="opening_balance.region_id"
                            :options="regionLists"
                            id="region_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :disabled="isWareHouseUser"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                        </ValidationProvider>
                      </b-col>
                       <b-col lg="6" sm="12">
                         <ValidationProvider name="District" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="district"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('warehouse_config.district') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="opening_balance.district_id"
                                :options="districtList"
                                id="district_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="isWareHouseUser"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Upazila" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="upazilla_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('warehouse_config.upazilla') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="opening_balance.upazilla_id"
                              :options="upazilaList"
                              id="upazilla_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              :disabled="isWareHouseUser"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Union" rules="">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="union_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('globalTrans.union') }} <span class="text-danger"></span>
                            </template>
                            <b-form-select
                              plain
                              v-model="opening_balance.union_id"
                              :options="unionList"
                              id="union_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              :disabled="isWareHouseUser"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                       <b-col lg="6" sm="12">
                        <ValidationProvider name="Warehouse Type" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="warehouse_type_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('warehouse_config.warehouse_type')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="opening_balance.warehouse_type_id"
                            :options="warehouseTypeList"
                            id="warehouse_type_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :disabled="isWareHouseUser"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Warehouse Name" vid="warehouse_name" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="warehouse_name"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{$t('warehouse_config.warehouse_name')}} <span class="text-danger">*</span>
                        </template>
                          <b-form-select
                            plain
                            v-model="opening_balance.warehouse_id"
                            :options="warehouseNameList"
                            id="warehouse_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :disabled="isWareHouseUser"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                       <b-col lg="6" sm="12">
                          <ValidationProvider name="Total Capacity" vid="total_capacity" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="total_capacity"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('warehouse_config.warehouse_capacity')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                plain
                                type="text"
                                v-model="opening_balance.total_capacity"
                                id="total_capacity"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled=isDisabled>
                                <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-input>

                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                      </b-col>
                    </b-row>
                      <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Establishment Date" vid="establishment_date" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="establishment_date"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('warehouse_config.establishment_date')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            plain
                            type="text"
                            v-model="opening_balance.establishment_date"
                            id="establishment_date"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :disabled=isDisabled>
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-input>

                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                        </ValidationProvider>
                      </b-col>
                       <b-col lg="6" sm="12">
                          <ValidationProvider name="Farmer Name" vid="farmer_id" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="farmer_id"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('warehouse_config.farmer_name')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="opening_balance.farmer_id"
                            :options="warehouseFarmerList"
                            id="farmer_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                            </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                    </b-row>
                      <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Crop Type" vid="commodity_group_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="commodity_group_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('warehouse_config.crop_type') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="opening_balance.commodity_group_id"
                            :options="commodityGroupList"
                            id="commodity_group_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                        </ValidationProvider>
                      </b-col>
                       <b-col lg="6" sm="12">
                        <ValidationProvider name="Crop Name" vid="commodity_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="commodity_id"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('warehouse_config.crop_name')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            plain
                            v-model="opening_balance.commodity_id"
                            :options="commoditynameList"
                            id="commodity_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>

                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                      <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Sack Quantity" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="sack_quantity"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('warehouse_config.sack_quantity')}} <span class="text-danger">*</span>
                            </template>
                          <b-form-input
                            id="sack_quantity"
                            v-model="opening_balance.sack_quantity"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      </b-col>
                       <b-col lg="6" sm="12">
                        <ValidationProvider name="Total Quantity" rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="total_quantity"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('warehouse_config.total_quantity')}} <span class="text-danger">*</span>
                              </template>
                            <b-form-input
                              id="total_quantity"
                              v-model="opening_balance.total_quantity"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                      <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Warehouse Level" vid="level_id" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="level_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('warehouse_config.warehouse-level') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="opening_balance.level_id"
                              :options="levelList"
                              id="level_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                       <b-col lg="6" sm="12">
                      </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col d-flex justify-content-end">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
            </b-form>
          </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '../../../../../config/api_config'
import { OpeningBalanceStore, OpeningBalanceUpdate, OpeningBalanceEdit } from '../../api/routes'

export default {
  name: 'FormLayout',
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getOpeningBalanceData()
      this.opening_balance = tmp
    } else {
      this.loader = false
    }
  },
  mounted () {
    core.index()
    if (this.isWareHouseUser) {
      this.opening_balance.division_id = this.authUser.office_detail.division_id
      this.opening_balance.region_id = this.regionDetailList.find(item => item.district_id === this.authUser.office_detail.district_id).region_id
      this.opening_balance.district_id = this.authUser.office_detail.district_id
      this.opening_balance.upazilla_id = this.authUser.office_detail.upazilla_id ?? 0
      this.opening_balance.union_id = this.authUser.office_detail.union_id ?? 0
      const warehouse = this.$store.state.warehouse.warehouseInfoList.find(doc => doc.office_id === this.authUser.office_detail.id)
      this.opening_balance.warehouse_id = warehouse.value
      this.opening_balance.warehouse_type_id = warehouse.warehouse_type_id
      this.warehouseTypeList = this.$store.state.warehouse.warehouseTypeList.filter(doc => doc.value === warehouse.warehouse_type_id)
    }
  },
  data () {
    return {
      loader: true,
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      opening_balance: {
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        warehouse_id: 0,
        region_id: 0,
        union_id: 0,
        warehouse_type_id: 0,
        total_capacity: '',
        establishment_date: '',
        farmer_id: 0,
        commodity_group_id: 0,
        commodity_id: 0,
        sack_quantity: '',
        level_id: 0,
        total_quantity: ''
      },
      districtList: [],
      upazilaList: [],
      unionList: [],
      tempList: [],
      warehouseTypeList: this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0),
      warehouseNameList: [],
      commoditynameList: [],
      levelList: [],
      warehouseFarmerList: []
    }
  },
  computed: {
    authUser () {
      return this.$store.state.Auth.authUser
    },
    isWareHouseUser () {
      return this.authUser.warehouse_id
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList
    },
    commodityGroupList: function () {
      return this.$store.state.warehouse.commodityGroupList.filter(item => item.status === 0)
    },
    regionDetailList: function () {
      return this.$store.state.warehouse.regionDetailList
    },
    regionLists: function () {
        return this.$store.state.warehouse.regionList.filter(item => item.status === 0)
    },
    isDisabled () {
      return true
    }
  },
  watch: {
    'opening_balance.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'opening_balance.region_id': function (newVal, oldVal) {
      this.getRegionDistrictList(newVal)
    },
    'opening_balance.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'opening_balance.upazilla_id': function (newVal, oldVal) {
         this.unionList = this.getUnionList(newVal)
      this.warehouseNameList = this.getWarehouseName(newVal, 'UPZ')
    },
    'opening_balance.union_id': function (newVal, oldVal) {
      this.warehouseNameList = this.getWarehouseName(newVal, 'UP')
    },
    'opening_balance.warehouse_type_id': function (newVal, oldVal) {
      this.warehouseNameList = this.getWarehouseName(newVal, 'WT')
    },
    'opening_balance.warehouse_id': function (newVal, oldVal) {
      this.opening_balance.total_capacity = this.getCapacity(newVal)
      this.warehouseFarmerList = this.getFarmerList(newVal)
      this.levelList = this.getLevelList(newVal)
    },
    'opening_balance.commodity_group_id': function (newVal, oldVal) {
      this.commoditynameList = this.getCommoNamelist(newVal)
    }
  },
  methods: {
    async getOpeningBalanceData () {
      this.loader = true
      const result = await RestApi.getData(warehouseServiceBaseUrl, `${OpeningBalanceEdit}/${this.$props.id}`)
      if (result.success) {
      this.opening_balance = result.data
      }
      this.loader = false
    },
    async register () {
      let result = null
      const loadinState = { loading: false, listReload: true }

      if (this.opening_balance.id) {
        result = await RestApi.putData(warehouseServiceBaseUrl, `${OpeningBalanceUpdate}/${this.id}`, this.opening_balance)
      } else {
        result = await RestApi.postData(warehouseServiceBaseUrl, OpeningBalanceStore, this.opening_balance)
      }
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data Update successfully' : 'Data saved successfully',
          color: '#D6E09B'
        })
        this.loader = true
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getUnionList (unionId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)

      if (unionId) {
        return unionList.filter(union => union.upazilla_id === unionId)
      }

      return unionList
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
        var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
        this.tempList = list
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
        this.tempList = list
      }

      if (Id && type === 'WT') {
        const filterData = this.tempList
        list = filterData.filter(warehouse => warehouse.warehouse_type_id === Id)
      }

      return list
    },
     getRegionDistrictList (regionId = null) {
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
          const dataObject = response.filter(document => document.region_id === regionId)
          dataObject.map(obj => {
            const district = this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id)
            if (typeof district !== 'undefined') {
              const item = {
                value: obj.district_id,
                text: this.$i18n.locale === 'bn' ? district.text_bn : district.text_en
              }
              this.districtList.push(item)
            }
          })
        }
      })
    },
    getCapacity (wareHouseID) {
      RestApi.getData(warehouseServiceBaseUrl, 'master-warehouse-info/list-allOp').then(response => {
        const dataObject = response.data.filter(warehouseinfo => warehouseinfo.id === wareHouseID)
        this.opening_balance.total_capacity = dataObject[0].total_capacity
        this.opening_balance.establishment_date = dataObject[0].establishment_date
      })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getCommoNamelist (categoryId) {
      RestApi.getData(warehouseServiceBaseUrl, 'master-commodity-name/list-all').then(response => {
        const dataObject = response.data.filter(subCategory => subCategory.commodity_group_id === categoryId)
        this.commoditynameList = dataObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.commodity_name_bn }
          } else {
            return { value: obj.id, text: obj.commodity_name }
          }
        })
      })
    },
    getLevelList (wareID) {
      RestApi.getData(warehouseServiceBaseUrl, 'master-warehouse-level/list-all').then(response => {
        if (response.success) {
          const dataObject = response.data.filter(item => item.warehouse_id === wareID)
          this.levelList = dataObject.map(obj => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.id, text: obj.level_name_bn }
            } else {
              return { value: obj.id, text: obj.level_name }
            }
          })
        }
      })
    },
    getFarmerList (wareID) {
      RestApi.getData(authServiceBaseUrl, '/user/farmer-list').then(response => {
        if (response) {
            const dataObject = response.data.filter(item => item.warehouse_id === wareID)
            this.warehouseFarmerList = dataObject.map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.username, text: obj.name_bn }
                } else {
                    return { value: obj.username, text: obj.name }
                }
            })
          }
        })
      }
  }
}

</script>
